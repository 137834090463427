@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&family=Nunito+Sans:wght@400;600&display=swap');

body {
    color: #505458;
    font-size: 15px;
    font-family: 'Nunito Sans', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Montserrat', sans-serif;
}

::-moz-selection {
    background: #465467;
    color: #ffffff;
}

::selection {
    background: #465467;
    color: #ffffff;
}


a {
    text-decoration: none !important;
    transition: all 400ms ease-in-out;
}

/* BUTTONS */

.btn {
    font-size: 12px;
    padding: 12px 20px;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1px;
}

.btn-sm {
    padding: 8px 14px;
}

.btn-dark-custom {
    background-color: #423f6d !important;
    border: 1px solid #423f6d !important;
    box-shadow: 0 3px 10px rgba(66, 63, 109, 0.4) !important;
    color: #ffffff;
}
.btn:hover{
    color: #ffffff;
}

.btn-custom {
    background-color: #3d62e0 !important;
    border: 1px solid #3d62e0 !important;
    box-shadow: 0 3px 10px rgba(61, 98, 224, 0.4) !important;
    color: #ffffff !important;
}

/* HELPER CLASSES */
h1 {
    font-size: 36px;
}

h2 {
    font-size: 30px;
}

h3 {
    font-size: 24px;
}

h4 {
    font-size: 17px;
}

h1,
h2,
h3 {
    margin-top: 5px;
    margin-bottom: 10px;
}

h4,
h5,
h6 {
    margin-top: 10px;
    margin-bottom: 10px;
}

.center-page {
    float: none;
    margin: 0 auto;
}

.section {
    padding-top: 100px;
    padding-bottom: 100px;
}

.section-md {
    padding-top: 70px;
    padding-bottom: 70px;
}

.section-lg {
    padding-top: 150px;
    padding-bottom: 150px;
}

.title {
    margin-top: 0px;
    font-size: 26px;
}

.title-alt {
    color: #767D8E;
    line-height: 24px;
    margin: 0px auto 50px auto;
    font-size: 15px;
}

.text-muted {
    color: #98a6ad !important;
}

.shape {
    position: absolute;
    pointer-events: none;
    right: 0;
    bottom: -2px;
    left: 0;
}

.shape>svg {
    transform: scale(2);
    width: 100%;
    height: auto;
    transform-origin: top center;
}



/* Home */
.home {
    background-color: #f2f6fa;
    padding-bottom: 150px;

}

.home-wrapper p {
    font-size: 16px;
    color: #787787;
}

.text-tran-box {
    background: #3d62e0;
    background: -webkit-linear-gradient(to left, #f1556c, #6658dd);
    background: linear-gradient(to left, #f1556c, #6658dd);
}

.text-transparent {
    background: #f2f6fa;
    color: #240e35;
    mix-blend-mode: lighten;
    overflow: hidden;
    font-size: 36px;
    margin-bottom: 40px;
    line-height: 54px;
    font-weight: 600;
}

.text-tran-box-dark .text-transparent {
    color: #fff;
    background: #343a40;
    mix-blend-mode: darken;
}

.home-alt p {
    color: #eee !important;
}

.home-alt h1 {
    color: #ffffff;
    font-size: 48px;
    margin-bottom: 40px;
    line-height: 54px;
    font-weight: 600;
}


/* Footer */
.footer {
    padding: 20px 0px;
    border-top: 1px solid #3B3B40;
}

.copyright {
    color: #b7b6c7;
    margin: 0px;
}

/* Footer-alt */
.footer-one {
    color: #9ba1ac;
    padding-bottom: 0px;
}

.footer-one h5 {
    color: #ffffff;
    font-size: 15px;
    margin-bottom: 20px;
    letter-spacing: 1px;

}

.footer-one .about-text {
    padding-right: 20px;
    line-height: 22px;
    margin-top: 10px;
}

.footer-one a {
    color: #9ba1ac;
    line-height: 28px;
}

.footer-one a:hover {
    color: #ffffff;
}

.footer-one ul li {
    margin: 5px 0px;
}

.footer-one-alt {
    padding: 20px 0px;
    border-top: 1px solid #3B3B40;
}

.footer-one-alt .copyright {
    line-height: 34px;
}

.footer-social-one {
    float: right;
    font-size: 16px;
    margin-bottom: 0px;
}

.footer-social-one li {
    padding: 0;
    margin: 0 2px !important;
}

.footer-social-one a {
    border-radius: 50%;
    text-align: center;
    background-color: #3c434a;
    width: 34px;
    display: block;
    height: 34px;
    line-height: 34px;
}

.footer-social-one a:hover {
    color: #ffffff;
    background-color: #3d62e0;
}

@media (min-width: 1200px) {
.container {
    max-width: 1500px;
}
}


.demo-box {
  padding: 20px 0 30px 0;
  text-align: center;
  transition: all .3s ease-in-out;
}

.demo-box:hover {
    transform: translateY(-0.5rem);
}

.demo-box a {
  border: 8px solid #ffffff;
  display: block;
  transition: all 400ms ease-in-out;
  box-shadow: 0 1px 50px rgba(153, 153, 153, 0.35);
}

.demo-box img {
    width: 100%;
    height: auto;
    position: relative;
    top: 0;
}

.demo-box h4 {
    text-transform: uppercase;
    font-weight: 700;
    margin-top: 20px;
    font-size: 14px;
}
.footer-img{
    width: 16px;
    height: 16px;
}
.bg-dark{
    background: #343a40!important;
}
